import React from "react";

import Layout from "../layouts/Layout";
import BannerSection from "../partials/BannerSection";
import CategoriesSection from "../partials/CategoriesSection";
import NetworkSection from "../partials/NetworkSection";
import InfoSection from "../partials/InfoSection";
import PlatformSection from "../partials/PlatformSection";
import CaseStudiesSection from "../partials/CaseStudiesSection";
import ContactSection from "../partials/ContactSection";
import { partnerLogos, brandLogos } from "../pages/index";

import { useMediaQuery } from "../utils/hooks";

const BrandsPage = props => {
  const {
    pageContext: { caseStudies },
  } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  const ctaButtons = [
    { label: "Start today", href: "/brand/signup" },
    { label: "Schedule a demo", href: "/book-a-demo" },
  ];

  return (
    <Layout
      pageTitle="Brands | Brandcrush"
      ogImage="/images/seo/brand.jpg"
      ogDescription="Engage consumers where they live, work, play and shop. Book retail media, experiential activations and partnerships via a network of trusted businesses."
    >
      <BannerSection
        theme="cerise"
        heading="Engage consumers where they shop, live, work & play"
        body="Seamlessly book shopper marketing, activations and partnerships through a network of trusted businesses"
        height={isMobile ? 36.625 : 30.25}
        marginBottom={isMobile ? 8.313 : 10.0}
        backgroundImgX={isMobile ? -2.0 : -2.0}
        backgroundImgY={isMobile ? -2.402 : -2.0}
        backgroundImgWidth={isMobile ? 21.0 : 46.438}
        backgroundImg="/images/cloud-brand.svg"
        foregroundImg="/images/brand-conversation.svg"
        foregroundImgX={isMobile ? 6.375 : 13.938}
        foregroundImgY={isMobile ? -5.813 : -1.5}
        foregroundImgWidth={isMobile ? 12.25 : 16.813}
        ctaButtons={[
          { label: "Start today", href: "/brand/signup" },
          { label: "Schedule a demo", href: "/book-a-demo", color: "white" },
        ]}
      />
      <CategoriesSection copy="Activate smarter and reach millions of targeted consumers" />
      <NetworkSection
        heading="Get cut-through with a warm introduction from these Media Owners and hundreds more"
        logos={partnerLogos(isMobile)}
        height="5.0"
        autoplay
        ctaButtons={ctaButtons}
      />
      <InfoSection
        leftHeading="Smart activations"
        leftBody="Discover thousands of untapped opportunities to reach consumers out and about, in their homes or online"
        leftColor="light-blue"
        middleHeading="Leverage trust"
        middleBody="A powerful network of partners who offer authentic connections with key consumer groups"
        middleColor="teal"
        rightHeading="Contextual marketing"
        rightBody="Reach targeted audiences at the right place, right time"
        rightColor="blue"
      />
      <PlatformSection
        heading={null}
        topHeading="Discover"
        topCopy="Thousands of activation spaces via a network of influential businesses"
        topGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/discover-rideshare@3x.png",
            x: isMobile ? 1.988 : 4.375,
            y: isMobile ? 10.37 : 22.813,
            delay: 0.1,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-recreation@3x.png",
            x: isMobile ? 10.767 : 23.688,
            y: isMobile ? 10.37 : 22.813,
            delay: 0.2,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-d2c@3x.png",
            x: isMobile ? 1.988 : 4.375,
            y: isMobile ? 8.04 : 17.688,
            delay: 0.3,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-retail@3x.png",
            x: isMobile ? 10.767 : 23.688,
            y: isMobile ? 8.04 : 17.688,
            delay: 0.4,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-health@3x.png",
            x: isMobile ? 1.988 : 4.375,
            y: isMobile ? 5.71 : 12.563,
            delay: 0.5,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-hospitality@3x.png",
            x: isMobile ? 10.767 : 23.688,
            y: isMobile ? 5.71 : 12.563,
            delay: 0.6,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-corporate@3x.png",
            x: isMobile ? 1.988 : 4.375,
            y: isMobile ? 3.381 : 7.438,
            delay: 0.7,
            width: isMobile ? 5.995 : 13.188,
          },
          {
            image: "/images/discover-shopping@3x.png",
            x: isMobile ? 10.767 : 23.688,
            y: isMobile ? 3.381 : 7.438,
            delay: 0.8,
            width: isMobile ? 5.995 : 13.188,
          },
        ]}
        middleHeading="Create"
        middleCopy="Hyper-targeted and scalable campaigns in minutes"
        middleGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/create-checklist@3x.png",
            x: isMobile ? 1.128 : 2.482,
            y: isMobile ? 6.449 : 14.188,
            delay: 0.2,
            height: isMobile ? 5.114 : 11.25,
          },
          {
            image: "/images/create-image@3x.png",
            x: isMobile ? 7.997 : 17.594,
            y: isMobile ? 5.568 : 12.25,
            delay: 0.3,
            height: isMobile ? 6.563 : 14.438,
          },
          {
            image: "/images/create-copy@3x.png",
            x: isMobile ? 7.997 : 17.594,
            y: isMobile ? 2.443 : 5.375,
            delay: 0.4,
            height: isMobile ? 2.557 : 5.625,
          },
        ]}
        bottomHeading="Activate"
        bottomCopy="Request to activate and hear a yay or nay from partners, or accept a proposal. Our team and workflows will guide you through the rest."
        bottomGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/activate-top-left@3x.png",
            x: isMobile ? 0.895 : 1.969,
            y: isMobile ? 7.851 : 17.272,
            delay: 0.1,
            height: isMobile ? 3.41 : 7.5,
          },
          {
            image: "/images/activate-top-right@3x.png",
            x: isMobile ? 9.787 : 21.531,
            y: isMobile ? 7.851 : 17.272,
            delay: 0.2,
            height: isMobile ? 3.41 : 7.5,
          },
          {
            image: "/images/activate-bottom-left@3x.png",
            x: isMobile ? 0.895 : 1.969,
            y: isMobile ? 2.737 : 6.022,
            delay: 0.3,
            height: isMobile ? 3.41 : 7.5,
          },
          {
            image: "/images/activate-bottom-right@3x.png",
            x: isMobile ? 9.787 : 21.531,
            y: isMobile ? 2.737 : 6.022,
            delay: 0.4,
            height: isMobile ? 3.41 : 7.5,
          },
        ]}
        ctaButtons={ctaButtons}
        theme="cerise"
      />
      <CaseStudiesSection color="navy" caseStudies={caseStudies} />
      <InfoSection
        leftHeading="Scalable"
        leftBody="Book with one partner, or scale national campaigns with hundreds"
        leftColor="light-pink"
        middleHeading="Transparent pricing"
        middleBody="No additional platform fees - all rates are determined by our partners"
        middleColor="purple"
        rightHeading="Simple payments"
        rightBody="Pay on a per-campaign basis by credit card, or invoice for approved Brands and Agencies"
        rightColor="cerise"
      />
      <NetworkSection
        heading="Trusted by the world's leading brands and agencies"
        logos={brandLogos(isMobile)}
        height="3.388"
        autoplay
        ctaButtons={ctaButtons}
      />
      <ContactSection
        heading="Need help?"
        body="We've got you; our team offers full concierge support"
        buttonLabel="Get in touch"
        backgroundColor="grey"
        buttonColor="navy"
        foregroundImg="/images/brand-contact.svg"
        foregroundImgX={4.438}
        foregroundImgY={isMobile ? -5.625 : -3.75}
        foregroundImgWidth={isMobile ? 18.875 : 37.938}
        height={24.375}
        href="/contact"
      />
    </Layout>
  );
};

export default BrandsPage;
